import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7550958a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "infos" }
const _hoisted_3 = { class: "total-value" }
const _hoisted_4 = {
  key: 0,
  class: "dataNone"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "list-content" }
const _hoisted_7 = { class: "list-content-right" }
const _hoisted_8 = { class: "right-top" }
const _hoisted_9 = ["innerHTML", "onClick"]
const _hoisted_10 = { class: "case-type" }
const _hoisted_11 = { class: "list-item-case" }
const _hoisted_12 = {
  key: 0,
  class: "case-item"
}
const _hoisted_13 = {
  key: 1,
  class: "case-item"
}
const _hoisted_14 = { class: "case-item" }
const _hoisted_15 = { class: "demo-pagination-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoData = _resolveComponent("NoData")!
  const _component_tool_box = _resolveComponent("tool-box")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(" 总共检索到 "),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.totalState.total.toLocaleString()), 1 /* TEXT */),
        _createTextVNode(" 篇 ")
      ]),
      (_ctx.listDataState.flag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_NoData)
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.listDataState.flag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createCommentVNode(" 功能框 "),
            _createVNode(_component_tool_box, {
              sortData: _ctx.sortState.data,
              sortId: _ctx.sortState.id,
              checkAllFlag: _ctx.checkedState.allFlag,
              onChecked: _ctx.checkedState.handleCheckAll,
              checkedAmount: _ctx.checkedState.checkedAmount,
              onChangeSort: _ctx.sortState.handleClick
            }, null, 8 /* PROPS */, ["sortData", "sortId", "checkAllFlag", "onChecked", "checkedAmount", "onChangeSort"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listDataState.data, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item-list",
                key: index
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_checkbox, {
                    class: "checkbox",
                    modelValue: item.flag,
                    "onUpdate:modelValue": ($event: any) => ((item.flag) = $event),
                    onChange: ($event: any) => (_ctx.checkedState.handleCheckSingle(item))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_tooltip, {
                        class: "item",
                        effect: "dark",
                        content: item.caseName,
                        placement: "top-start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: item.caseName,
                            class: "list-content-name",
                            onClick: ($event: any) => (_ctx.checkToGo({
                    name: 'DetailCasePageV2',
                    query: {
                      id: item.id,
                      sourceFlag: true,
                      keyword: _ctx.listDataState.searchData
                    }
                  }))
                          }, null, 8 /* PROPS */, _hoisted_9)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.caseTypeName), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (item.cause)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(`${item.cause && item.cause.join(",").replace(/<[^>]+>/g,
                '')}`), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (item.caseNo)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(item.caseNo), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(item.publicationDate.split("T")[0]), 1 /* TEXT */),
                      _createCommentVNode(" :title=\"'案由：' + i.case\" "),
                      _createCommentVNode(" 部分数据由html片段包含 "),
                      _createCommentVNode(" <p  :title=\"'案由：' + item.cause.replace(/<[^>]+>/g, '')\" >\n                {{ `${item.cause.replace(/<[^>]+>/g, '')}` }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;\n                {{ item.caseNo }} &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;\n                {{ item.publicationDate.split(\"T\")[0] }}\n              </p> ")
                    ])
                  ]),
                  _createCommentVNode(" 操作下拉框 "),
                  _createCommentVNode(" <handle-box :currentI=\"item\"> </handle-box> ")
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ])), [
      [_directive_loading, _ctx.listDataState.loading]
    ]),
    _createCommentVNode(" 分页 "),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.paginationState.currentPage,
        "onUpdate:currentPage": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paginationState.currentPage) = $event)),
          _ctx.paginationState.handleCurrentChange
        ],
        layout: "prev, pager, next, jumper",
        total: _ctx.paginationState.pageTotal,
        "prev-text": _ctx.paginationState.prevText,
        "next-text": _ctx.paginationState.nextText
      }, null, 8 /* PROPS */, ["currentPage", "total", "onUpdate:currentPage", "prev-text", "next-text"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}