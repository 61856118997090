
import { defineComponent } from 'vue'
import { ArrowDown, Download } from '@element-plus/icons-vue'
import { isCollect } from '@/utils/collect'
import { isDowmload } from '@/utils/download'
export default defineComponent({
  name: '操作下拉框',
  components: {
    ArrowDown,
    Download
  },
  props: {
    currentI: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    const isFavor = (i: any) => {
      i.favor = !i.favor
      isCollect(i.favor, i.id, 4)
    }
    return {
      isFavor,
      isDowmload,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
