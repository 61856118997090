import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1ecd8e32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LeftPane" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree = _resolveComponent("tree")!
  const _component_con_box = _resolveComponent("con-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 结案类型 "),
    _createVNode(_component_con_box, {
      class: "case-type",
      title: _ctx.caseType.title,
      conHight: 440
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.caseType,
          onChange: _ctx.caseType.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"]),
    _createCommentVNode(" 裁判年份 "),
    _createVNode(_component_con_box, {
      title: _ctx.jalxState.title,
      slotH: 10
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tree, {
          state: _ctx.jalxState,
          onChange: _ctx.jalxState.handleItemClick
        }, null, 8 /* PROPS */, ["state", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ]))
}