import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-667f75f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.curData, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(['item', { active: _ctx.state.defaultId === item.id }]),
          key: item.id,
          onClick: ($event: any) => (_ctx.handleItemClick(item))
        }, _toDisplayString(item.name) + "（" + _toDisplayString(item.value.toLocaleString()) + "） ", 11 /* TEXT, CLASS, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" 分页 "),
    _createVNode(_component_pagination, {
      data: _ctx.state.data,
      curPage: _ctx.curPageNumber,
      onChange: _ctx.handlePageClick
    }, null, 8 /* PROPS */, ["data", "curPage", "onChange"])
  ]))
}