
import { defineComponent, reactive, watch, ref } from 'vue'
import httpRequest from '@/service'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import conBox from './cpns/conBox.vue'
import list from './cpns/list.vue'
import tree from './cpns/tree.vue'
interface IObj {
  id: string
  [key: string]: any
}
interface ILeftPaneState {
  title: string
  data: IObj[]
  defaultId: string
  level?: string
  url?: string
  loading?: boolean
  getData: () => void
  handleItemClick: (obj: any) => void
}
export default defineComponent({
  name: 'LeftPane',
  components: {
    conBox,
    list,
    tree
  },
  setup() {
    const store = useStore()
    const route = useRoute();
    watch(
      () => [store.state.formFlag, store.state.form],
      ([newFlag, newForm], [oldFlag]) => {
        [
          jalxState.defaultId,
          caseType.defaultId
        ] = [
            store.state.form.year,
            store.state.form.caseType
          ]
        caseType.getData()
        jalxState.getData()

      },
      {
        deep: true
      }
    )

    // 点击每一项，需要判断检索栏中是否已有该类别
    const searchListAction = (obj: any) => {
      let arr = store.state.searchList
      let typeFlag = arr.some((item: any) => {
        if (item.type === obj.type) {
          return true
        }
      })
      let nameFlag = arr.some((item: any) => {
        if (item.name === obj.name) {
          return true
        }
      })
      if (typeFlag && !nameFlag) {
        arr.splice(
          arr.findIndex((i: any) => obj.type === i.type),
          1
        )
        arr.push(obj)
        store.commit('setSearchList', arr)
      } else if (typeFlag && nameFlag) {
        return
      } else if (!typeFlag) {
        arr.push(obj)
        store.commit('setSearchList', arr)
      }
    }

    // 案件类型
    const caseType: ILeftPaneState = reactive({
      title: '案件类型',
      key: 'id  ', // 根据返回数据确定
      data: [],
      defaultId: store.state.form.caseType,
      loading: true,
      getData() { //接口待定
        caseType.loading = true
        httpRequest
          .post({ url: 'caseTypeCount', data: store.state.form })
          .then((res: any) => {
            caseType.data = res.data
            caseType.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        caseType.defaultId = obj.id
        const filterObj = store.state.form
        filterObj.caseType = obj.id
        store.commit('setForm', filterObj)
        searchListAction(obj)
      }
    })
    caseType.getData()
    //结案时间
    const jalxState: ILeftPaneState = reactive({
      title: '结案时间',
      key: 'name', // 根据返回数据确定
      data: [],
      defaultId: '',
      loading: true,
      getData() { //接口待定
        jalxState.loading = true
        httpRequest
          .post({ url: 'yearCount', data: store.state.form })
          .then((res: any) => {
            jalxState.data = res.data
            jalxState.loading = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleItemClick(obj: any) {
        jalxState.defaultId = obj.label
        const filterObj = store.state.form
        filterObj.year = obj.label
        store.commit('setForm', filterObj)
        searchListAction(obj)
      }
    })
    jalxState.getData()


    return {
      caseType,
      jalxState
    }
  }
})
